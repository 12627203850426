import React from 'react';
import './ConfirmationModal.css';

export default function ConfirmationModal({ onConfirm, onCancel, enableFluidDoc, setEnableFluidDoc }) {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Start Recording</h2>
        <p>Are you sure you want to start recording? This will capture the current session.</p>
        
        <div className="fluid-doc-option">
          <input
            type="checkbox"
            id="enableFluidDoc"
            checked={enableFluidDoc}
            onChange={() => setEnableFluidDoc(!enableFluidDoc)}
          />
          <label htmlFor="enableFluidDoc">Enable Fluid Doc Tool</label>
          <p className="fluid-doc-description">
            Fluid Doc tool allows you to convert spoken language into text automatically with high accuracy, perfect for documenting patient consultations.
          </p>
        </div>
        
        <div className="modal-buttons">
          <button onClick={onConfirm} className="confirm-button">Start Recording</button>
          <button onClick={onCancel} className="cancel-button">Cancel</button>
        </div>
      </div>
    </div>
  );
}
