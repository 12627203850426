import React, { useEffect, useRef, useCallback, useState } from 'react';
import {
  useLocalParticipant,
  useVideoTrack,
  useDevices,
  useDaily,
  useDailyEvent,
} from '@daily-co/daily-react-hooks';
import * as tf from '@tensorflow/tfjs';
import '@tensorflow/tfjs-backend-webgl'; // Import the WebGL backend
import '@tensorflow/tfjs-backend-wasm'; // Import the WASM backend
import UserMediaError from '../UserMediaError/UserMediaError';

import './HairCheck.css';

export default function HairCheck({ joinCall, cancelCall }) {
  const localParticipant = useLocalParticipant();
  const videoTrack = useVideoTrack(localParticipant?.session_id);
  const { microphones, speakers, cameras, setMicrophone, setCamera, setSpeaker } = useDevices();
  const callObject = useDaily();
  const videoElement = useRef();

  const [getUserMediaError, setGetUserMediaError] = useState(false);
  const [userName, setUserName] = useState(localParticipant?.user_name || '');
  const [isNameEmpty, setIsNameEmpty] = useState(false);

  useDailyEvent(
    'camera-error',
    useCallback(() => {
      setGetUserMediaError(true);
    }, []),
  );

  const onChange = (e) => {
    const name = e.target.value;
    setUserName(name);
    callObject.setUserName(name);
    if (name) {
      setIsNameEmpty(false); // Reset the error if the name is provided
    }
  };

  const join = (e) => {
    e.preventDefault();
    if (!userName.trim()) {
      setIsNameEmpty(true);
      return; // Do not proceed if the name is empty
    }
    joinCall();
  };

  useEffect(() => {
    // Set the backend to WebGL, with a fallback to WASM if necessary
    tf.setBackend('webgl').catch(() => tf.setBackend('wasm'));

    if (!videoTrack.persistentTrack) return;

    const stream = new MediaStream([videoTrack.persistentTrack]);
    videoElement.current.srcObject = stream;
  }, [videoTrack.persistentTrack]);

  const updateMicrophone = (e) => {
    setMicrophone(e.target.value);
  };

  const updateSpeakers = (e) => {
    setSpeaker(e.target.value);
  };

  const updateCamera = (e) => {
    setCamera(e.target.value);
  };

  return (
    <>
      {getUserMediaError ? (
        <UserMediaError />
      ) : (
        <form className="hair-check" onSubmit={join}>
          <h1>Setup your hardware</h1>
          {/* Video preview */}
          <div className="video-container">
            {videoTrack?.persistentTrack && (
              <video ref={videoElement} autoPlay muted playsInline />
            )}
          </div>

          {/* Username */}
          <div>
            <label htmlFor="username">Your name:</label>
            <input
              name="username"
              type="text"
              placeholder="Enter your name"
              onChange={onChange}
              value={userName}
              className={isNameEmpty ? 'input-error' : ''}
            />
            {isNameEmpty && <p className="error-message">Name is required.</p>}
          </div>

          {/* Microphone select */}
          <div>
            <label htmlFor="micOptions">Microphone:</label>
            <select name="micOptions" id="micSelect" onChange={updateMicrophone}>
              {microphones?.map((mic) => (
                <option key={`mic-${mic.device.deviceId}`} value={mic.device.deviceId}>
                  {mic.device.label}
                </option>
              ))}
            </select>
          </div>

          {/* Speakers select */}
          <div>
            <label htmlFor="speakersOptions">Speakers:</label>
            <select name="speakersOptions" id="speakersSelect" onChange={updateSpeakers}>
              {speakers?.map((speaker) => (
                <option key={`speaker-${speaker.device.deviceId}`} value={speaker.device.deviceId}>
                  {speaker.device.label}
                </option>
              ))}
            </select>
          </div>

          {/* Camera select */}
          <div>
            <label htmlFor="cameraOptions">Camera:</label>
            <select name="cameraOptions" id="cameraSelect" onChange={updateCamera}>
              {cameras?.map((camera) => (
                <option key={`cam-${camera.device.deviceId}`} value={camera.device.deviceId}>
                  {camera.device.label}
                </option>
              ))}
            </select>
          </div>

          <button className="button2" type="submit">
            Join call
          </button>
          <button onClick={cancelCall} className="cancel-call">
            Back to start
          </button>
        </form>
      )}
    </>
  );
}
