import React from 'react';
import './HomeScreen.css';
import Header from '../Header/Header';

export default function HomeScreen({ createCall, startHairCheck }) {
  const startDemo = () => {
    createCall().then((url) => {
      startHairCheck(url);
    });
  };

  return (
    <>
      <div className="home-screen">
        {/* <img className= "image-logo-homesrceen"src="/images/logo.jpg" alt="Daily logo" />
        <br/>
        <h1>ThincHealth - Transforming the healthcare experience.</h1>
        <br/> */}
        <h1>Your healthcare, reimagined. Connect with doctors, track your health, and get the care you need—all from the comfort of your home.</h1>
        <p>   Start the video by clicking the button below. </p>
        <button className = "button1" onClick={startDemo}>Click to start a call &#x279C;</button>
        <p className="small">Select “Allow” to use your camera and mic for this call if prompted</p>
      </div>
    </>
  );
}
