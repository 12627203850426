import React from 'react';
import './StopRecordingModal.css';

export default function StopRecordingModal({ onConfirm, onCancel }) {
  return (
    <div className="stop-recording-modal-overlay">
      <div className="stop-recording-modal">
        <h2>Stop Recording</h2>
        <p>Are you sure you want to stop the recording? This will end the current session recording.</p>
        <div className="stop-recording-modal-actions">
          <button className="stop-recording-confirm-button" onClick={onConfirm}>Yes, Stop Recording</button>
          <button className="stop-recording-cancel-button" onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
}
