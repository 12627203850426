import { useCallback, useState } from 'react';
import { useAppMessage, useLocalParticipant } from '@daily-co/daily-react-hooks';
import './Chat.css';

export default function Chat({ showChat, toggleChat }) {
  const localParticipant = useLocalParticipant();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const sendAppMessage = useAppMessage({
    onAppMessage: useCallback(
      (ev) =>
        setMessages((messages) => [
          ...messages,
          {
            msg: ev.data.msg,
            name: ev.data.name,
          },
        ]),
      [],
    ),
  });

  const sendMessage = useCallback(
    (message) => {
      sendAppMessage(
        {
          msg: message,
          name: localParticipant?.user_name || 'Guest',
        },
        '*',
      );

      setMessages([
        ...messages,
        {
          msg: message,
          name: localParticipant?.user_name || 'Guest',
        },
      ]);
    },
    [localParticipant, messages, sendAppMessage],
  );

  const onChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!inputValue) return;
    sendMessage(inputValue);
    setInputValue('');
  };

  return showChat ? (
    <aside className="chat">
      <div className="chat-header">
        <h2>Notes</h2>
        <button onClick={toggleChat}>X</button>
      </div>
      <ul className="chat-messages">
        {messages?.map((message, index) => (
          <li key={`message-${index}`} className="chat-message">
            <span className="chat-message-author">Note</span>: 
            <p className="chat-message-body">{message?.msg}</p>
          </li>
        ))}
      </ul>
      <div className="add-message">
        <form className="chat-form" onSubmit={handleSubmit}>
          <input
            className="chat-input"
            type="text"
            placeholder="Type your note here.."
            value={inputValue}
            onChange={onChange}
          />
          <button type="submit" className="chat-submit-button">Send</button>
        </form>
      </div>
    </aside>
  ) : null;
}
