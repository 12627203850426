// src/guards/firebase/Firebase.tsx
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import "firebase/compat/storage"; // Make sure storage is imported


const firebaseConfig = {
  apiKey: "AIzaSyC5SriSdwDiDFFAram4bKzeRwlWsIGR1GM",
  authDomain: "fluid-doc-3ee08.firebaseapp.com",
  projectId: "fluid-doc-3ee08",
  storageBucket: "fluid-doc-3ee08.appspot.com",
  messagingSenderId: "1011091902559",
  appId: "1:1011091902559:web:43f371b126838ab1d90bea"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const storage = firebase.storage();
const firestore = firebase.firestore();
const Auth = firebase.auth();

export { firebase, storage, firestore, Auth };