import './Tool.css';
import {
    Card,
    CardTitle,
    CardBody,
    Button
} from "shards-react";

export default function Tool({ showTool, toggleTool, isRecordingEnabled }) {
    return showTool ? (
        <aside className="tool">
            <div className="tool-banner">
                <p>🔧 We are currently integrating these tools. They will be launched soon!</p>
            </div>
            <div className="tool-list">
                <Card className="tool-card">
                    <CardBody className="tool-card-body">
                        <CardTitle className="tool-card-title">Transcription with Fluid Doc</CardTitle>
                        <p className="tool-card-description">
                            Convert spoken language into text automatically with high accuracy,
                            perfect for documenting patient consultations.
                        </p>
                        <Button
                            className="tool-card-button"
                            disabled={!isRecordingEnabled}
                        >
                            {isRecordingEnabled ? 'Use Tool &rarr;' : 'Coming Soon &rarr;'}
                        </Button>
                    </CardBody>
                </Card>
                <Card className="tool-card">
                    <CardBody className="tool-card-body">
                        <CardTitle className="tool-card-title">Skin Detection</CardTitle>
                        <p className="tool-card-description">
                            Analyze skin conditions using AI-powered detection, providing instant insights
                            on potential dermatological issues.
                        </p>
                        <Button className="tool-card-button" disabled>
                            Coming Soon &rarr;
                        </Button>
                    </CardBody>
                </Card>
                <Card className="tool-card">
                    <CardBody className="tool-card-body">
                        <CardTitle className="tool-card-title">Range of Motion</CardTitle>
                        <p className="tool-card-description">
                            Assess a patient's range of motion to aid in diagnosis and track rehabilitation progress.
                        </p>
                        <Button className="tool-card-button" disabled>
                            Coming Soon &rarr;
                        </Button>
                    </CardBody>
                </Card>
            </div>
            <div className="tool-footer">
                <button onClick={toggleTool} className="close-tool">Close Tool</button>
            </div>
        </aside>
    ) : null;
}
