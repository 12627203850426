import React, { useState, useCallback } from 'react';
import {
  useDaily,
  useScreenShare,
  useLocalParticipant,
  useVideoTrack,
  useAudioTrack,
  useDailyEvent,
} from '@daily-co/daily-react-hooks';

import MeetingInformation from '../MeetingInformation/MeetingInformation';
import Chat from '../Chat/Chat';
import TheTool from '../Tool/Tool';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import StopRecordingModal from '../StopRecordingModal/StopRecordingModal';
import RecordingComponent from '../RecordingComponent/RecordingComponent';

import './Tray.css';
import {
  CameraOn,
  Leave,
  CameraOff,
  MicrophoneOff,
  MicrophoneOn,
  Screenshare,
  Info,
  ChatIcon,
  ChatHighlighted,
  Tool,
  RecordingIcon,
} from './Icons';

export default function Tray({ leaveCall }) {
  const callObject = useDaily();
  const { isSharingScreen, startScreenShare, stopScreenShare } = useScreenShare();

  const [showMeetingInformation, setShowMeetingInformation] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [newChatMessage, setNewChatMessage] = useState(false);
  const [showTool, setShowTool] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [showStartRecordingModal, setShowStartRecordingModal] = useState(false);
  const [showStopRecordingModal, setShowStopRecordingModal] = useState(false);
  const [enableFluidDoc, setEnableFluidDoc] = useState(false);

  const [triggerStartRecording, setTriggerStartRecording] = useState(false);
  const [triggerStopRecording, setTriggerStopRecording] = useState(false);

  const localParticipant = useLocalParticipant();
  const localVideo = useVideoTrack(localParticipant?.session_id);
  const localAudio = useAudioTrack(localParticipant?.session_id);
  const mutedVideo = localVideo.isOff;
  const mutedAudio = localAudio.isOff;

  useDailyEvent(
    'app-message',
    useCallback(() => {
      if (!showChat) {
        setNewChatMessage(true);
      }
    }, [showChat]),
  );

  const toggleVideo = useCallback(() => {
    callObject.setLocalVideo(mutedVideo);
  }, [callObject, mutedVideo]);

  const toggleAudio = useCallback(() => {
    callObject.setLocalAudio(mutedAudio);
  }, [callObject, mutedAudio]);

  const toggleScreenShare = () => (isSharingScreen ? stopScreenShare() : startScreenShare());

  const toggleMeetingInformation = () => {
    setShowMeetingInformation(!showMeetingInformation);
  };

  const toggleChat = () => {
    setShowChat(!showChat);
    setShowTool(false);
  };

  const toggleTool = () => {
    setShowTool(!showTool);
    setShowChat(false);
  };

  const toggleRecording = () => {
    if (isRecording) {
      setShowStopRecordingModal(true);
    } else {
      setShowStartRecordingModal(true);
    }
  };

  const confirmStartRecording = () => {
    console.log('Confirming start recording...');
    setIsRecording(true);
    setTriggerStartRecording(true);
    if (enableFluidDoc) {
      console.log('Fluid Doc tool enabled.');
      // Logic to enable Fluid Doc tool
    }
    setShowStartRecordingModal(false);
  };

  const confirmStopRecording = () => {
    console.log('Confirming stop recording...');
    setIsRecording(false);
    setTriggerStopRecording(true);
    setShowStopRecordingModal(false);
  };

  const cancelStartRecording = () => {
    setShowStartRecordingModal(false);
  };

  const cancelStopRecording = () => {
    setShowStopRecordingModal(false);
  };

  return (
    <div className="tray">
      {showMeetingInformation && <MeetingInformation />}
      <Chat showChat={showChat} toggleChat={toggleChat} />
      <TheTool showTool={showTool} toggleTool={toggleTool} isRecordingEnabled={isRecording} />
      <RecordingComponent 
        triggerStartRecording={triggerStartRecording} 
        triggerStopRecording={triggerStopRecording} 
      />
      <div className="tray-buttons-container">
        <div className="controls">
          <button onClick={toggleVideo}>
            {mutedVideo ? <CameraOff /> : <CameraOn />}
            {mutedVideo ? 'Turn Camera On' : 'Turn Camera Off'}
          </button>
          <button onClick={toggleAudio}>
            {mutedAudio ? <MicrophoneOff /> : <MicrophoneOn />}
            {mutedAudio ? 'Unmute Mic' : 'Mute Mic'}
          </button>
          <button onClick={toggleRecording}>
            <RecordingIcon />
            {isRecording ? 'Stop Recording' : 'Start Recording'}
          </button>
        </div>
        <div className="actions">
          <button onClick={toggleScreenShare}>
            <Screenshare />
            {isSharingScreen ? 'Stop Sharing Screen' : 'Share Screen'}
          </button>
          <button onClick={toggleMeetingInformation}>
            <Info />
            {showMeetingInformation ? 'Hide Info' : 'Show Info'}
          </button>
          <button onClick={toggleChat}>
            {newChatMessage ? <ChatHighlighted /> : <ChatIcon />}
            {showChat ? 'Hide Note' : 'Show Note'}
          </button>
          <button onClick={toggleTool}>
            <Tool />
            {showTool ? 'Hide Tools' : 'Show Tools'}
          </button>
        </div>
        <div className="leave">
          <button onClick={leaveCall}>
            <Leave /> Leave Call
          </button>
        </div>
      </div>
      {showStartRecordingModal && (
        <ConfirmationModal
          onConfirm={confirmStartRecording}
          onCancel={cancelStartRecording}
          enableFluidDoc={enableFluidDoc}
          setEnableFluidDoc={setEnableFluidDoc}
        />
      )}
      {showStopRecordingModal && (
        <StopRecordingModal
          onConfirm={confirmStopRecording}
          onCancel={cancelStopRecording}
        />
      )}
    </div>
  );
}
