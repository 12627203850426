import React, { useState, useRef, useEffect } from 'react';
import { firestore, firebase } from '../../Firebase.tsx'; // Adjust the import path if necessary

export default function RecordingComponent({ triggerStartRecording, triggerStopRecording }) {
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [downloadURL, setDownloadURL] = useState(null);
  const audioRef = useRef(null); // Reference to the audio element

  useEffect(() => {
    if (triggerStartRecording) {
      startRecording();
    }
  }, [triggerStartRecording]);

  useEffect(() => {
    if (triggerStopRecording) {
      stopRecording();
    }
  }, [triggerStopRecording]);

  const startRecording = async () => {
    console.log('Starting audio recording...');
    
    try {
      // Request audio stream
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('Audio Stream:', stream);
      audioRef.current.srcObject = stream;
  
      // Check if the stream has audio tracks
      const audioTracks = stream.getAudioTracks();
      if (audioTracks.length === 0) {
        console.error('No audio tracks available in the stream.');
        return;
      }
  
      // Set up MediaRecorder with fallback for MIME type
      let options = { mimeType: 'audio/webm' };
      if (!MediaRecorder.isTypeSupported(options.mimeType)) {
        options = { mimeType: 'audio/ogg' };
        console.log('Falling back to audio/ogg MIME type.');
      }
  
      const recorder = new MediaRecorder(stream, options);
  
      // Handle data availability
      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          setRecordedChunks((prev) => [...prev, event.data]);
        } else {
          console.warn('No data available from the recording.');
        }
      };
  
      // Handle recording stop
      recorder.onstop = async () => {
        if (recordedChunks.length === 0) {
          console.error('No audio data available.');
          return;
        }
  
        const blob = new Blob(recordedChunks, {
          type: options.mimeType,
        });
  
        const fileName = `recording-${Date.now()}.webm`;  // Or 'ogg' based on the MIME type
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(fileName);
  
        console.log('Uploading audio recording to Firebase Storage...');
        const snapshot = await fileRef.put(blob);
        const downloadURL = await snapshot.ref.getDownloadURL();
        setDownloadURL(downloadURL);
        console.log('Audio recording uploaded successfully. Download URL:', downloadURL);
  
        console.log('Saving metadata to Firestore...');
        await firestore.collection('recordings').add({
          url: downloadURL,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          duration: blob.size / 1000, // Duration in seconds (approximate)
        });
        console.log('Metadata saved to Firestore.');
      };
  
      // Start recording
      recorder.start();
      setMediaRecorder(recorder);
      setIsRecording(true);
      console.log('Recording started successfully.');
      
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };
  

  const stopRecording = () => {
    console.log('Stopping audio recording...');
    if (mediaRecorder && isRecording) {
      mediaRecorder.stop();
    } else {
      console.error('No active recording found.');
    }
    setIsRecording(false);
  };

  return (
    <div>
      <audio ref={audioRef} autoPlay muted /> {/* Replace video with audio element */}
      <div>
        {downloadURL && (
          <div>
            <p>Recording complete! <a href={downloadURL} target="_blank" rel="noopener noreferrer">Download</a></p>
          </div>
        )}
      </div>
    </div>
  );
}
