import React from 'react';
import './Header.css';
import OpenInNew from '@mui/icons-material/OpenInNew';
import LinkedIn from '@mui/icons-material/LinkedIn';
import Close from '@mui/icons-material/Close';

export default function Header({ onClose }) {
  return (
    <header>
      <div className="header-section">
        <img className="image-logo" src="/images/logo.jpg" alt="Thinchealth logo" />
        <span className="title">
           ThincHealth - Bring the healthcare to your comfort of home
        </span>
      </div>
      <div className="header-section">
        <a
          className="new-tab-link"
          href="https://thinchealth.org"
          target="_blank"
          rel="noreferrer noopener">
          <span> Visit our website and learn more about us</span>
          <img src="/images/newtab.svg" alt="New tab" />
        </a>
        <a
          className="linkedin-link" 
          href="https://www.linkedin.com/company/thinchealth/"
          target="_blank"
          rel="noreferrer noopener">
          <img src="/images/linkedin.png" alt="LinkedIn" />
        </a> 
        <button className="close-button" onClick={onClose}>
          <Close />
        </button>
      </div>
    </header>
  );
}
